<template>
    <v-card id="receipt-list">
        <!-- table -->
        <v-data-table
            v-model="selectedTableData"
            :headers="tableColumns"
            :footer-props="footerProps"
            :items="receiptListTable"
            :options.sync="options"
            :server-items-length="totalReceiptListTable"
            :loading="loading"
            class="text-no-wrap">
            <!-- id -->
            <template #[`item.id`]="{ item }">
                <router-link class="font-weight-medium text-decoration-none" :to="{ path: `/moloni/receipts/${item.document_id}` }"> #{{ item.document_set_name }}/{{ item.number }} </router-link>
            </template>

            <!-- status  -->
            <template #[`item.status`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                            size="30"
                            :color="resolveReceiptStatusVariantAndIcon(item).variant"
                            :class="`v-avatar-light-bg ${resolveReceiptStatusVariantAndIcon(item).variant}--text`"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon size="18" :color="resolveReceiptStatusVariantAndIcon(item).variant">
                                {{ resolveReceiptStatusVariantAndIcon(item).icon }}
                            </v-icon>
                        </v-avatar>
                    </template>
                    <span>{{ getTrad(text)[resolveReceiptStatusVariantAndIcon(item).text] }}</span>
                </v-tooltip>
            </template>

            <!-- client -->
            <template #[`item.client`]="{ item }">
                <div class="d-flex align-center">
                    <v-avatar :color="resolveClientAvatarVariant(item)" :class="`v-avatar-light-bg ${resolveClientAvatarVariant(item)}--text`" size="30">
                        <!--  <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img> -->
                        <span class="font-weight-medium">{{ avatarText(item.entity_name) }}</span>
                    </v-avatar>

                    <div class="d-flex flex-column ms-3">
                        <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.entity_name }}</span>
                        <span class="text-xs">{{ item.entity_vat }}</span>
                    </div>
                </div>
            </template>

            <!-- total -->
            <template #[`item.total`]="{ item }"> {{ item.net_value }}€</template>

            <!-- Balance -->
            <template #[`item.balance`]="{ item }">
                <span v-if="checkType(item.reconciled_value) !== 'number'">
                    {{ item.reconciled_value }}
                </span>

                <!-- chip -->
                <v-chip
                    v-else
                    small
                    :color="item.net_value == item.reconciled_value ? 'success' : 'error'"
                    :class="`v-chip-light-bg ${item.net_value == item.reconciled_value ? 'success' : 'error'}--text font-weight-semibold`">
                    {{ item.net_value == item.reconciled_value ? getTrad(text).paid : getTrad(text).unpaid }}
                </v-chip>
            </template>

            <!-- date -->
            <template #[`item.date`]="{ item }">
                <span class="text-no-wrap">{{ $moment(item.date).format('DD-MM-YYYY') }}</span>
            </template>

            <!-- actions -->
            <template #[`item.actions`]="{ item }">
                <div class="d-flex align-center justify-center">
                    <!-- view  -->
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" :to="{ path: `/moloni/receipts/${item.document_id}` }" v-on="on">
                                <v-icon size="18">
                                    {{ icons.mdiEyeOutline }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ getTrad(text).see }}</span>
                    </v-tooltip>
                    <!-- download -->
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn icon small v-bind="attrs" v-on="on" @click="downloadReceipt(item.document_id)" :disabled="item.status == 0">
                                <v-icon size="18">
                                    {{ icons.mdiDownloadOutline }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ getTrad(text).download }}</span>
                    </v-tooltip>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import { mdiTrendingUp, mdiPlus, mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiDownloadOutline, mdiPencilOutline, mdiContentCopy } from '@mdi/js';

    import { onUnmounted, ref } from '@vue/composition-api';
    import { avatarText } from '@core/utils/filter';
    import store from '@/store';

    // store module
    import receiptStoreModule from '../receiptStoreModule';

    // composition function
    import useReceiptsList from './useReceiptList';
    import getTrad from '../../../i18n/getTrad';
    export default {
        mixins: [getTrad],
        setup() {
            const INVOICE_APP_STORE_MODULE_NAME = 'moloni-receipts';

            // Register module
            if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
                store.registerModule(INVOICE_APP_STORE_MODULE_NAME, receiptStoreModule);
            }

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
            });

            const {
                receiptListTable,
                searchQuery,
                tableColumns,
                footerProps,
                options,
                statusFilter,
                totalReceiptListTable,
                loading,
                selectedTableData,
                resolveReceiptStatusVariantAndIcon,
                resolveClientAvatarVariant,
            } = useReceiptsList();

            const statusOptions = ref(['Downloaded', 'Draft', 'Paid', 'Partial Payment', 'Past Due']);

            const actions = ['Delete', 'Edit', 'Send'];

            const selectedAction = ref('');

            const actionOptions = [
                { title: 'Download', icon: mdiDownloadOutline },
                { title: 'Duplicate', icon: mdiContentCopy },
            ];

            const checkType = (data) => {
                if (typeof data === 'number') return 'number';
                if (typeof data === 'string') return 'string';

                return 0;
            };

            const downloadReceipt = (receipt) => {
                store
                    .dispatch('moloni-receipts/fetchReceiptDownloadLink', { id: receipt })
                    .then((response) => {
                        document.location.href = response.data.data.url;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            };
            const text = 'views.receipt.list';
            return {
                tableColumns,
                footerProps,
                searchQuery,
                statusOptions,
                statusFilter,
                options,
                totalReceiptListTable,
                receiptListTable,
                loading,
                actions,
                selectedTableData,
                actionOptions,
                selectedAction,

                checkType,
                avatarText,
                resolveReceiptStatusVariantAndIcon,
                resolveClientAvatarVariant,
                downloadReceipt,

                icons: {
                    mdiTrendingUp,
                    mdiPlus,
                    mdiDownloadOutline,
                    mdiDotsVertical,
                    mdiEyeOutline,
                },
                text,
            };
        },
        created() {
            this.tableColumns.forEach((column) => {
                column.text = this.getTrad(this.text).columns[column.text];
            });
            this.footerProps['items-per-page-text'] = this.getTrad(this.text).itemsPerPageText;
        },
    };
</script>

<style lang="scss" scoped>
    #receipt-list {
        .receipt-list-actions {
            max-width: 7.81rem;
        }
        .receipt-list-search {
            max-width: 10.625rem;
        }
        .receipt-list-status {
            max-width: 11.3rem;
        }
    }
</style>
